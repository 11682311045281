import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import HomeView from '../views/dashboard/HomeView.vue'
import UserLogin from '../views/login/UserLogin.vue';
import FormularioRegistrarse  from '../views/login/FormularioRegistrarse .vue';

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/registrarse/:clave?',
    name: 'FormularioRegistrarse',
    component: FormularioRegistrarse,
    props: route => ({ claveProp: route.params.clave }) // Aquí pasas el parámetro como prop al componente
  },
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },    
  },
  // {
  //   path: '/dashboard/user/edit/:token', // Asegúrate de que el token es el parámetro correcto que necesitas
  //   name: 'EditUser',
  //   component: EditUser,
  //   props: true // Esto permite que el token se pase como una prop al componente
  // },
  // {
  //   path: '/user-details/:userId',
  //   name: 'UserDetails',
  //   component: () => import('../components/metrics/UserMetrics/DetaillUser.vue'),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/dashboard/usuarios/agregar-usuario/:groupId',
  //   name: 'AgregarUsuario',
  //   component: AgregarUsuario,
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/registrarse/:clave?',
  //   name: 'Registrarse',
  //   component: Registrarse,
  //   props: route => ({ claveProp: route.params.clave }) // Aquí pasas el parámetro como prop al componente
  // },
  // {
  //   path: '/403',
  //   name: 'Forbidden',
  //   component: Forbidden
  // },
  // {
  //   path: '/dashboard/usuarios',
  //   name: 'DashboardUsuarios',
  //   component: DashboardUsuarios,
  //   meta: { requiresAuth: true }  // Sólo asegura que el usuario debe estar autenticado para acceder
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
